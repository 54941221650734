@import "./bootstrap/bootstrap";
@import 'animate.css';

html {
	font-size: 15px;
}

#root, body, #App {
	height: 100vh;
	width: 100vw;
}

body {
	transition: background-color 0.2s linear;

	&.dark-theme {
		.text-muted {
			color: #d3d3d3 !important;
		}
	}
}



