.dropdown {
  & .dropdown-toggle::after {
    transition: 0.15s ease;
  }

  &.show {
    & .dropdown-toggle::after {
      transform: rotate(180deg);
    }
  }
}


