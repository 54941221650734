.navbar-toggler.animated {
  & span {
    display: block;
    background-color: #92969F;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    transition: all 0.35s ease-out;
    transform-origin: center left;

    &:nth-child(1) {
      margin-top: 0.3em;
    }

    &:nth-child(1) {
      transform: translate(0%, 0%) rotate(0deg);
    }

    &:nth-child(2) {
      opacity: 1;
    }

    &:nth-child(3) {
      transform: translate(0%, 0%) rotate(0deg);
    }
  }

  &:not(.collapsed) span {
    &:nth-child(1) {
      transform: translate(15%, -33%) rotate(45deg);
    }

    &:nth-child(2) {
      opacity: 0;
    }

    &:nth-child(3) {
      transform: translate(15%, 33%) rotate(-45deg);
    }
  }
}
