// Body
//$body-bg: #f8fafc;
$body-bg: white;

// Typography
$font-family-sans-serif: 'Poppins', 'sans-serif';
$font-size-base: 1rem;
$line-height-base: 1.6;


// Colors
//$primary: rgba(48, 63, 92, 0.91);
$primary: #303F5C;
$secondary: white;
$success: #4ea772;
$info: #80B7D6;
$warning: #F4A347;
$danger: #E86559;
$light: #fafafa;
$dark: rgb(36, 44, 62);


// Links
$link-decoration: underline;


// Modals

$modal-content-border-radius: 1rem;
$modal-content-bg: transparent;
$modal-title-line-height: 1;

$modal-xl: 1140px !default;
$modal-lg: 980px !default;
$modal-md: 550px !default;
$modal-sm: 300px !default;


// Forms
$input-padding-x: 0.75rem;
$input-placeholder-color: #adb5bd;
$input-color: #343a40;

$border-radius: .25rem;
$border-radius-lg: .25rem;
$border-radius-sm: .25rem;


// Pagination
$pagination-border-width: 0;
$pagination-color: #b7b7b7;
$pagination-bg: white;

$pagination-disabled-color: #e7e7e7;

$pagination-active-color: $dark;
$pagination-active-bg: white;


// Buttons
$input-btn-padding-x: 2.2rem;
$btn-border-radius: .25rem;

$btn-padding-x-sm: 1.9rem;
$btn-border-radius-sm: .25rem;

$btn-padding-x-lg: 2.8rem;
$btn-border-radius-lg: .25rem;


// Grid
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1367px,
        xxxl: 1540px
);

