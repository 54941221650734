@import "../../assets/css/bootstrap/variables";
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../../node_modules/bootstrap/scss/variables';

#certificate-page {
  background: linear-gradient(to bottom right, $primary, $dark);

  .certificate {
    box-shadow: 0 0.5rem 1rem rgba(#000, 0.57) !important;
    max-width: 800px;
    width: 100%;
  }

  .certificates-container {
    &.hidden {
      filter: opacity(0%);
    }

    -webkit-transition: 0.5s -webkit-filter ease-in-out;
    -moz-transition: 0.5s -moz-filter ease-in-out;
    -moz-transition: 0.5s filter ease-in-out;
    -ms-transition: 0.5s -ms-filter ease-in-out;
    -o-transition: 0.5s -o-filter ease-in-out;
    transition: 0.5s filter ease-in-out, 0.5s -webkit-filter ease-in-out;
  }

  .button-share-on-linkedin {
    background: #0575B4 !important;
    border-color: #0575B4 !important;

    &, p, img {
      height: 38px;
    }

    p {
      border-left: 2px solid #01609d;
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}