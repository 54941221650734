@import "_variables";
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/mixins/_breakpoints';
@import '~bootstrap/scss/_variables';


.modal-body, .modal-header, .modal-footer {
  background: white;
}

.modal-header {
  border: none !important;

  & .close {
    color: gray !important;

    & > span:not(.sr-only) {
      font-weight: initial !important;
      display: inline-block;
      border: 2px solid gray;
      border-radius: 50%;
      height: 1.5rem;
      width: 1.5rem;
      line-height: 1.2rem;
    }
  }
}

.modal-dialog.modal-xl {
  @include media-breakpoint-down(sm) {
    max-width: 100%;
    margin: 0 !important;

    &, & .modal-content {
      max-height: 100% !important;
      height: 100% !important;
    }

    .modal-content, .modal-header {
      border-radius: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    height: calc(100% - 3.5rem);
    max-width: 95%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1070px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1150px;
  }

  .modal-header-content {
    img {
      height: 4rem;
    }
  }
}


.modal-footer {
  border: none !important;
}

.modal-left, .modal-right {
  .modal-dialog {
    position: fixed;
    margin: auto;
    height: 100%;
    width: 100%;
    max-height: 100% !important;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
  }

  .modal-content {
    max-height: 100% !important;
    height: 100%;
    width: 100%;

    border: none;
  }

  .modal-body {
    padding: 15px 15px 15px;
  }
}

/*Left*/
.modal-left.fade {
  left: -320px;
  -webkit-transition: opacity 0.5s linear, left 0.3s ease-out !important;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out !important;
  transition: opacity 0.3s linear, left 0.3s ease-out !important;

  &.show {
    left: 0;
  }
}

.modal-left {
  .modal-content, .modal-header {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}

.modal-right {
  .modal-content, .modal-header {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

/*Right*/
.modal-right.fade {
  transform: translate(320px);
  right: -320px;
  left: unset !important;

  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;

  & .modal-dialog {
    right: 0;
  }

  &.show {
    transform: translate(0);
    right: 0;
  }
}

