#policies-container {
  bottom: 45px;
  z-index: 999;

  #policies-info {
    z-index: 9999;
    font-size: 90%;
    border-radius: 10px;
    box-shadow: rgb(92, 92, 92) 0 0 10px;
  }
}

