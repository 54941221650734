@import "_variables";

label {
  font-weight: 500 !important;
}

.required:after {
  content: " *";
  color: red;
}

.form-inline {
  & label {
    margin-right: 0.8rem;
  }
}

.form-check-input {
  position: initial;
  margin-left: 0;
}

.datetime {
  & .timepicker-group {
    margin-top: 33px;
  }
}

.form-group {
  & p {
    line-break: anywhere !important;
  }
}


