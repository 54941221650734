#certificates-header {
  min-height: 75px;
  background: transparent;

  > div {
    max-width: 1920px;
  }

  img {
    height: 50px;
  }

  button {
    border-radius: 4px !important;
  }


  a {
    border-right: 2px solid rgba(255, 255, 255, 0.5);
  }
}
