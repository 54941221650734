@import "./_variables";

table {
  & thead {
    & tr {
      background: $primary;
      color: $warning;
    }

    & th {
      white-space: nowrap;
    }

    & a {
      color: $warning;

      &:hover {
        color: $secondary;
      }
    }
  }

  & .td-actions {
    white-space: nowrap;
    width: 1%;

    & *:not(.dropdown-item) {
      margin: 0 3px;
    }

    & .dropdown-item.text-danger:active {
      color: white !important;
    }
  }

  & .column-sorted {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $info;
    }

    &.sorted-by-this {
      color: $info;
    }

    &::before {
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      margin-right: 5px;
    }

    &:not(.asc-sorted):not(.desc-sorted)::before {
      content: "\f0dc";
    }

    &.asc-sorted::before {
      content: "\f160";
    }

    &.desc-sorted::before {
      content: "\f161";
    }
  }
}
